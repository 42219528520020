import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function Accomodations(props){
  return(<div style={{marginTop:"100px", marginBottom:"100px"}}>{
    <Container className="mt-3 mb-3">
      <div style={{marginTop:"-200px", height:"200px"}} id="accomodations" name="accomodations"></div>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={5}>
          <Alert variant="warning">
            <Alert.Heading><Alert.Link href="https://tidewaterinn.com/" target="_blank">The Tidewater Inn</Alert.Link></Alert.Heading>
              <p>
                101 E. Dover Street, Easton, Maryland 21601-3001
              </p>
              <p>
                Give them a call at <Alert.Link href="tel:410-822-1300">(410) 822-1300</Alert.Link>
              </p>
              <p>
                Be sure to mention the <b>MacLure-Byrne Wedding!</b>
              </p>
          </Alert>
        </Col>
        <Col xs={12} md={8} lg={5}>
          <Alert variant="warning">
            <Alert.Heading><Alert.Link href="https://www.ihg.com/holidayinnexpress/hotels/us/en/easton/easex/hoteldetail?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=EASEX&qGrpCd=MBW&setPMCookies=true&qSHBrC=EX&qDest=8561%20Ocean%20Gateway,%20Easton,%20MD,%20US&srb_u=1" target="_blank">Holiday Inn Express</Alert.Link></Alert.Heading>
              <p>
                8561 Ocean Gateway Route 50, Easton, Maryland 21601
              </p>
              <p>
                Follow the link above, or give them a call at <Alert.Link href="tel:410-819-6500">(410) 819-6500</Alert.Link>
              </p>
              <p>
                Be sure to mention the <b>MacLure-Byrne Wedding!</b>
              </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  }</div>)
}
