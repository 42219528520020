import {Alert, Row, Col, Tabs, Tab, Button, ListGroup} from 'react-bootstrap';
import {useEffect, useRef, useState} from 'react';

/*

Watering Holes

*/

const information = {
    "General Information":[
        {
            "title":"Talbot County",
            "description":"The Talbot County Office of Tourism",
            "link":"https://tourtalbot.org/resources/maps-brochures/"
        },
        {
            "title":"Easton",
            "description":"Arts and cultural center, and county seat of Talbot County",
            "link":"https://eastonmd.org/"
        },
        {
            "title":"St. Michael's",
            "description":"Historic town with a beautiful harbor",
            "link":"https://www.stmichaelsmd.org/"
        },
    ],
    "Watering Holes":[
        {
            "title":"Washington Street Pub",
            "description":"Classic bar fare & raw shellfish pair with beers & speciality drinks in cozy surrounds.",
            "link":"https://washingtonstreetpub.com",
        },
        {
            "title":"Banning's Tavern",
            "description":"Local tavern named for an 18th-century sea captain with American pub fare, craft brews & cocktails.",
            "link":"http://www.banningstavern.com/",
        },
        {
            "title":"Doc's Downtown Grille",
            "description":"Upbeat American restaurant featuring a full bar, TVs for sports & a regular happy hour.",
            "link":"http://www.docsdowntowngrille.com",
        },
        {
            "title":"Tiger Lily",
            "description":"The tiki bar the east coast needed 🍍",
            "link":"https://www.tigerlilyeaston.com/"
        }
    ],
    "Restaurants":[
        {
            "title":"Rude Burger & BBQ",
            "description":"VOTED SHORE'S BEST SCRATCH-MADE BBQ & BURGERS. ",
            "link":"https://www.rudefoodco.com/",
        },
        {
            "title":"Scossa Italian Restaurant",
            "description":"Classy setting for Northern Italian cuisine includes an elegant lounge & a quaint terrace.",
            "link":"http://www.scossarestaurant.com/",
        },
        {
            "title":"Legal Assetts",
            "description":"Craft Food and Spirits in historic downtown Easton, MD.",
            "link":"https://www.facebook.com/LegalAssets",
        },
        {
            "title":"Bas Rouge",
            "description":"Contemporary European restaurant offering an impeccable integration of fine dining, a world-class wine list, and elegant service.",
            "link":"https://basrougeeaston.com/",
        },
        {
            "title":"The Wardroom",
            "description":"An epicurean market showcasing hand-selected and house-made artisan products, premium ingredients, and wines from top vintners worldwide.",
            "link":"https://www.thewardroom.com/",
        },
        {
            "title":"Roma Alla Pala",
            "description":"Fresh, prime ingredients and a commitment to making our pizza in an authentic Roman style promise a unique experience.",
            "link":"https://www.romaeaston.com/",
        },
    ],
    "Breakfast":[
        {
            "title":"Rise Up",
            "description":"Laid-back operation known for its small-batch, locally roasted coffee made with organic beans.",
            "link":"https://riseupcoffee.com/our-locations/",
        },
        {
            "title":"Breakfast in Easton",
            "description":"Breakfast only place downtown.",
            "link":"http://breakfastineaston.com",
        },
    ],
    "Sports":[
        {
            "title":"Easton Cycle and Sport",
            "description":"Rent bikes, paddle boards, kayak",
            "link":"https://ecs.bike/",
        },
        {
            "title":"Hog Neck Golf Course",
            "description":"18-hole Championship Talbot County golf course as well as 9-hole Executive course",
            "link":"https://www.hogneck.com",
        },
    ],
    "Historic and Nature Tours":[
        {
            "title":"Chesapeake Bay Maritime Museum",
            "description":"Home to a collection of Chesapeake Bay artifacts, exhibitions, and vessels. ",
            "link":"https://cbmm.org",
        },
        {
            "title":"Blackwater National Wildlife Refuge",
            "description":"Established in 1933 as a waterfowl sanctuary for birds migrating along the Atlantic Flyway. (40+ min drive)",
            "link":"https://www.fws.gov/refuge/blackwater",
        },
    ],
    "Tours by Sea":[
        {
            "title":"Patriot Cruises",
            "description":"A 65-70 minute scenic river cruise",
            "link":"https://www.patriotcruises.com/",
        },
        {
            "title":"Sail Selina",
            "description":"Relaxing Chesapeake Bay Sailing Tours, Sailboat Rides & Sightseeing Boat Trips",
            "link":"https://sailselina.com/",
        },
        {
            "title":"Oxford-Bellevue Ferry",
            "description":"Crossings every 15–20 minutes with the traffic  – No reservations needed!",
            "link":"https://oxfordferry.com/",
        }
    ]
}

export default function Information(){

    const mapRef = useRef();
    const [category, setCategory] = useState("General Information");
    const [place, setPlace] = useState("");
    const [mapWidth, SetMapWidth] = useState("");

    const API_KEY = "AIzaSyCyqrgX7s64wkR084bk0Vb6UHzskNg6Fro";

    useEffect(()=>SetMapWidth(mapRef.current?mapRef.current.clientWidth*0.9:"0"),[mapRef])

    return(
        <Row style={{"textAlign":"left"}} id="things-to-do" md={1} lg={2}>
            <Col className="text-center" ref={mapRef}>
                <iframe
                    title='Information Map'
                    width={mapWidth}
                    height="450"
                    loading="lazy"
                    allowfullscreen
                    src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}
                    &q=${place}Easton+MD`}>
                </iframe>
            </Col>
            <Col>
                <div>
                    {Object.keys(information).map( (cat, idx)=>
                        <Button variant={cat===category?"light":"outline-light"} className="m-1" key={idx} onClick={()=>setCategory(cat)}>
                            {cat}
                        </Button>
                    )}
                </div>
                <ListGroup>
                    {information[category].map( (place, idx) =>
                        <ListGroup.Item className="mt-1" action href={place.link} onMouseEnter={()=>setPlace(place.title)} onMouseExit={()=>setPlace("")}>
                            <div><strong>{place.title}</strong></div>
                            <div className="text-muted">{place.description}</div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Col>
        </Row>
    )
}