import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Logout = (props) => {

  const handleLogout = () => props.app.currentUser.logOut()
    .then(response=>{
      props.setUser(null);
      window.location.reload();
    })
    .catch(err=>console.error(err))

  return(
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>
          Are you sure you wish to sign out?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You will be brought back to the login screen.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
        <Button variant="danger" onClick={handleLogout}>Sign Out</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Logout;
