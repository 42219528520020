import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import laughing from './img/laughing.jpeg';
import signature from './img/signature.png';

export default function Welcome(props){
  return(<div style={{fontSize:'25px'}}>{
    <Container className="mb-5 welcome-container">
      <div style={{height:"200px"}}></div>
      <Row xs={1} md={1} lg={2} id="welcome">
        <Col className="mb-3">
          <Image src={laughing} roundedCircle fluid/>
        </Col>
        <Col className="mt-auto mb-auto">
          <div>
            <p>
              Welcome to the website for our <b>October 8th, 2022</b> wedding in <b>Easton, MD!</b>
            </p>
            <p>
              Here, you will find information on:
              <ul>
                <li><a href="#accomodations" id="ref-link">Accommodations</a></li>
                <li><a href="#schedule" id="ref-link">The Schedule</a></li>
                <li><a href="#directions" id="ref-link">Directions</a></li>
              </ul>
            </p>
            <p>
              Best,
            </p>
            <p>
              <Image src={signature} height="50vh"/>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  }</div>)
}
