import {useEffect, useState} from 'react';

import lock from './img/lock.gif';
import unlock from './img/unlock.gif';
import emailPic from './img/email.png';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';

export default function Login(props){

  const [status, setStatus] = useState('waiting');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const credentials = props.Realm.Credentials.function({email, password});
    setStatus('logging-in')
    props.app.logIn(credentials)
      .then(resp=>{
        setStatus('logged-in')
        setTimeout(()=>props.setUser(resp),3000)
      })
      .catch(err=>{
        setStatus('error')
        console.error(err);
      })
  }

  useEffect(()=>password.length===8?handleLogin():null,[password, handleLogin])

  return(
    <Modal show={props.show} backdrop="static" centered className="login-page">
      <Container className="p-3">
          <InputGroup as={Col}>
            <InputGroup.Text>
              <Image src={emailPic} height="50"/>
            </InputGroup.Text>
            <Form.Control value={email} onChange={(e)=>setEmail(e.target.value)}
              type="email" size="lg" className="text-center" disabled={status==='logged-in'}
              placeholder="What is the Best Email to Contact you?"/>
          </InputGroup>
          <InputGroup as={Col}>
            <InputGroup.Text>
              <Image src={props.user?unlock:lock} height="50"/>
            </InputGroup.Text>
            <Form.Control value={password} onChange={(e)=>setPassword(e.target.value)}
              type="password" size="lg" className="text-center"
              placeholder="What is Our Favorite Card Game?" disabled={status==='logged-in'}
              />
          </InputGroup>
          {
            status==='waiting'?null:
            status==='logging-in'?
              <Alert variant="warning">
                Logging In
              </Alert>:
            status==='error'?
              <Alert variant="danger">
                Error
              </Alert>:
              <Alert variant="success">
                Logged In!
              </Alert>
          }
      </Container>
    </Modal>
  )
}
