// Styling
import './App.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// React
import {useState} from 'react';
// Images
import black from './img/initialsBlack.png';
// Sections
import Welcome from './sections/Welcome'
import Schedule from './sections/Schedule'
import Accommodations from './sections/Accomodations'
import Directions from './sections/Directions';
import Footer from './sections/Footer';
import FadeInSection from './sections/FadeInSection';
import Information from './sections/Information';
// Popups
import Logout from './popups/Logout';
import Login from './popups/Login';
// Bootstrap
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import * as Realm from 'realm-web'

const app = new Realm.App({id:"weddingwebsite-koxkj"});

export default function App(){

  const [user, setUser] = useState(app.currentUser)
  const [logOut, setLogout] = useState(false);

  return(
    <div className="App">
      <Navbar expand="md" className="justify-content-center">
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
          <Navbar.Brand onClick={()=>setLogout(true)}><Image src={black} fluid/></Navbar.Brand>
          <Nav>
            <Nav.Link href="#welcome">Welcome</Nav.Link>
            <Nav.Link href="#accomodations">Accommodations</Nav.Link>
            <Nav.Link href="#schedule">Schedule</Nav.Link>
            <Nav.Link href="#directions">Directions</Nav.Link>
            <Nav.Link href="#directions">Information</Nav.Link>
            <Nav.Link href="#things-to-do">Things to Do</Nav.Link>
            <Nav.Link href="https://www.zola.com/registry/maclurebyrnewedding">Registry</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Navbar>
      <Container>
        {
          [ <Welcome/>,
            <Accommodations/>,
            <Schedule/>,
            <Directions/>,
            <Information/>
          ].map(section=>
                <FadeInSection children={section}/>
        )}
      </Container>
      <Footer/>
      <Logout app={app} setUser={setUser} show={logOut} onHide={()=>setLogout(false)}/>
      <Login show={!user} setUser={setUser} app={app} Realm={Realm}/>
    </div>
  )
}
