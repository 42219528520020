import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';


import waze from './img/waze.png';
import gmaps from './img/gmaps.png'
import amaps from './img/amaps.png'

const locations = [
  {name:"Trinity Cathedral", lat:"38.7762215", lon:"-76.072699"},
  {name:"The Tidewater Inn", lat:"38.7744867", lon:"-76.074448"},
  {name:"Holiday Inn Express", lat:"38.7839217", lon:"-76.0605292"},
]

export default function Direction(props){
  return (<div>{
    <Container style={{marginTop:"200px", marginBottom:"200px"}}>
      <div style={{marginTop:"-200px", height:"200px"}} id="directions"></div>
      <Row style={{width:"400px", marginLeft:"auto", marginRight:"auto"}}>
        <Col>
          <Table>
            <tbody>
              {locations.map(location=>
                <tr>
                  <th className="text-white">{location.name}</th>
                  <td>
                    <DropdownButton title="Get Directions" size="sm" drop="down">
                      <Dropdown.Item href={`https://www.waze.com/ul?ll=${location.lat}%2C${location.lon}&navigate=yes&zoom=17`}
                        className="text-center" target="_blank" eventKey="0">
                        <Image src={waze} height="50"/>
                      </Dropdown.Item>
                      <Dropdown.Item href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat}%2C${location.lon}`}
                        className="text-center" target="_blank" eventKey="1">
                        <Image src={gmaps} height="50"/>
                      </Dropdown.Item>
                      <Dropdown.Item href={`http://maps.apple.com/?daddr=${location.lat}%2C${location.lon}`}
                        className="text-center" target="_blank" eventKey="2">
                        <Image src={amaps} height="50"/>
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  }</div>)
}
