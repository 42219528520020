import "../App.css";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

const events = {
  Saturday:[
    {time:'4 pm', name:'Ceremony', location:<a href="https://goo.gl/maps/b6fRuDTXNahz4ThTA">Trinity Cathedral</a>, guests:""},
    {time:'5:30 pm', name:'Cocktails, Dinner, and Dancing', location:<a href="https://goo.gl/maps/4tBmjtPiRsD4UHQm6">Tidewater Inn</a>, guests:""},
  ],
  Sunday:[
    {time:'9 am - 11 am', name:'Breakfast', location:<a href="https://goo.gl/maps/4tBmjtPiRsD4UHQm6">Tidewater Inn</a>, guests:""},
  ]
}

export default function Schedule(props){
  return(
    <div>{
        <Container className="schedule-container">
          <div id="schedule" style={{marginTop:"-200px", height:"200px"}}></div>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={6}>
              <Table>
                {
                  Object.entries(events).map(([day, events],idx)=>
                    <tbody>
                      <tr><th colspan="3">{day} October {idx+8}th</th></tr>
                      {events.map(event=>
                        <tr>
                          <th>{event.time}</th>
                          <td>{event.name}</td>
                          <td>{event.location}</td>
                        </tr>
                      )}
                    </tbody>
                )}
              </Table>
            </Col>
          </Row>
        </Container>
    }</div>
  )
}
